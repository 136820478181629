import React, { useState } from 'react'
import { Dialog } from 'primereact/dialog'
import { verifyPaymentUrl, checkPaymentUrl } from '../sub_pages/BaseUrl'

const VerifyPayment = () => {
    const [transaction_id, setTransaction_id] = useState('')
    const [tx_ref, setTx_ref] = useState('')
    const [status, setStatus] = useState('')
    const [visible, setVisible] = useState(false)
    const [msg, setMsg] = useState('')
    const [verifying, setVerifying] = useState(false)
    const [updating, setUpdating] = useState(false)
    const [error, setError] = useState(false)


    const checkPayment = async (e) => {
      e.preventDefault()
      setVerifying(true)
      if (!transaction_id) {
        setError('transaction id is required')
        setVisible(true)
        setVerifying(false)
        return
      }
      try {
        const res = await fetch(checkPaymentUrl + `/${transaction_id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        })
        const data = await res.text()
        if (!res.ok) {
          throw Error(data)
        }
        const resData = JSON.parse(data)
        setMsg(resData)
        setVerifying(false)
        setVisible(true)
      } catch (error) {
        console.log(error.message)
        setError(error.message)
        setVerifying(false)
        setVisible(true)
      }
    }


    const submitData = async (e) => {
        e.preventDefault()
        // console.log('submitting data');
        setUpdating(true)
        if (!transaction_id || !tx_ref || !status) {
            setError('All fields are required')
            setVisible(true)
            setUpdating(false)
            return
        }
        let verifyPayUrl = `${verifyPaymentUrl}?status=${status}&tx_ref=${tx_ref}&transaction_id=${transaction_id}`
        console.log(verifyPayUrl);
        try{
            const res = await fetch(verifyPayUrl, {
                method: 'GET',
                headers: {
                    'Content-type': 'application/json',
                },
                
            })
            const data = await res.text()
            let resData = ''
            if (!res.ok) {
                throw Error(data)
            }
            resData = JSON.parse(data)
            if (resData.success) {
              setMsg(resData.success)
              setUpdating(false)
              setVisible(true)
            }else{
              setMsg(resData)
              setUpdating(false)
              setVisible(true)
            }
            
        }catch(error){
            console.log(error.message);
            setError(error.message)
            setUpdating(false)
            setVisible(true)
        }
        
    }

  return (
    <div className=' h-[100vh] flex justify-center items-center'>
      <div className='bg-slate-300 w-[50vw] px-3 py-5 rounded-lg'>
        <h3 className='text-center text-2xl mt-3'>Verify Payment</h3>
        <div className='mb-2'>
          <div>
            <label htmlFor='transactionId' className=''>
              Transaction Id:
            </label>
          </div>
          <div>
            <input
              type='text'
              name='transactionId'
              id='transactionId'
              required
              placeholder='Transaction Id'
              className='w-full rounded-md p-2'
              onChange={(e) => setTransaction_id(e.target.value)}
            />
          </div>
        </div>
        <div className='mb-2'>
          <div>
            <label htmlFor='transactionId' className=''>
              Transaction Reference:
            </label>
          </div>
          <div>
            <input
              type='text'
              name='transactionRfx'
              id='transactionRfx'
              required
              placeholder='Transaction Reference'
              className='w-full rounded-md p-2'
              onChange={(e) => setTx_ref(e.target.value)}
            />
          </div>
        </div>
        <div className='mb-2'>
          <div>
            <label htmlFor='status' className=''>
              Status:
            </label>
          </div>
          <div>
            <input
              type='text'
              name='status'
              id='status'
              required
              placeholder='Status'
              className='w-full rounded-md p-2'
              onChange={(e) => setStatus(e.target.value)}
            />
          </div>
        </div>
        <div className='mt-3 flex'>
          <button
            type='submit'
            className='bg-yellow-600 text-white p-2 rounded-l-md w-full'
            onClick={(e) => checkPayment(e)}
            disabled={verifying}
          >
            {verifying ? 'Loading...' : 'Verify Payment'}
          </button>
          <button
            type='submit'
            className='bg-blue-600 text-white p-2 rounded-r-md w-full'
            onClick={(e) => submitData(e)}
            disabled={updating}
          >
            {updating ? 'Loading...' : 'Update Payment'}
          </button>
        </div>
      </div>
      <Dialog
        header='Payment verification'
        visible={visible}
        style={{ width: '50vw' }}
        onHide={() => setVisible(false)}
      >
        {msg === 'Book already purchased!' && (
          <div>
            <p>
              Payment Status:{' '}
              <span className='text-green-600 text-xl'>{msg}</span>
            </p>
          </div>
        )}
        {msg !== 'Book already purchased!' && (
          <div>
            <p>
              Payment Status:{' '}
              <span className='text-green-600 text-xl'>{msg.status}</span>
            </p>
            <p>Transaction Id: {msg.data?.id}</p>
            <p>Transaction Reference: {msg.data?.tx_ref}</p>
            <p>Amount: {msg.data?.amount}</p>
            <p>Payment created_at: {msg.data?.created_at}</p>
            <p>Data status: {msg.data?.status}</p>
            <h4 className='my-3 text-2xl uppercase underline'>
              Customer details
            </h4>
            <p>Email: {msg.data?.customer?.email}</p>
            <p>Phone: {msg.data?.customer?.phone_number}</p>
          </div>
        )}
        {error && <p className='text-red-600'>{error}</p>}
      </Dialog>
    </div>
  )
}

export default VerifyPayment
