import React from 'react'
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className='not-found py-6'>
      <h2 className='text-center text-3xl font-mono py-3 my-3'>Sorry...</h2>
      <p className='text-center font-mono py-3 my-3'>Page cannot be found</p>
      <div className='text-center font-mono py-3 my-3'>
        <Link to='/'>Back to the homepage...</Link>
      </div>
    </div>
  )
}

export default NotFound