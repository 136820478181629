import { useAuthContext } from './useAuthContext'
import { useNavigate } from 'react-router-dom'
import { logoutUserUrl } from '../components/sub_pages/BaseUrl'

export const useLogout = () => {
  const { dispatch } = useAuthContext()
  const navigate = useNavigate()
  const logout = () => {
    const user = JSON.parse(localStorage.getItem('user'))
    const token = JSON.parse(localStorage.getItem('token'))
    const jwt = token.token
    const url = logoutUserUrl + '?UserId=' + user.Id

    //remove token from localstorage
    localStorage.removeItem('user')
    localStorage.removeItem('token')
    fetch(url, {
      method: 'Post',
      headers: { Authorization: 'Bearer ' + jwt },
    })
      .then((res) => {
        if (!res.ok) {
          if (res.status === 401) {
            throw Error('Unauthorised')
          } else {
            throw Error('could not fetch data from the source')
          }
        }
        // console.log(res.json());
      })
      .catch((err) => {
        console.log(err.message)
      })
    if (localStorage.getItem('user') === null) {
      //dispatch logout function
      dispatch({ type: 'LOGOUT' })

      navigate('/login')
    }
  }

  return { logout }
}
