import React, { useState } from 'react'
import useFetch from '../../hooks/useFetch'
// import UserList from '../sub_pages/UserList'
import Loading from '../sub_pages/Loading'
import Breadcrumbs from '../sub_pages/Breadcrumbs'
import { useLocation } from 'react-router-dom'
import { getAllUserUrl } from '../sub_pages/BaseUrl'
// import Pagination from '../sub_pages/Pagination'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { FilterMatchMode } from 'primereact/api'
import UserDropDownAction from '../sub_pages/UserDropDownAction'

const Users = () => {

  const location = useLocation()
  // var history = useHistory()
 
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  })

  const { data: users, isPending, error } = useFetch(getAllUserUrl)


  const viewActions = (user) => {
    return <UserDropDownAction userId={user.Id} />
  }


  return (
    <div className='container mx-auto md:px-8 min-h-screen'>
      {!isPending && (
        <Breadcrumbs
          location={location.pathname}
          userSearchKeyword=''
        />
      )}
      {isPending && <Loading />}
      {error && <div className='text-red-600'>{error}</div>}
      
      {users && (
        <>
          <input
            type='text'
            placeholder='Search'
            className='w-full p-3 rounded-md my-3'
            onInput={(e) =>
              setFilters({
                global: {
                  value: e.target.value,
                  matchMode: FilterMatchMode.CONTAINS,
                },
              })
            }
          />
          <DataTable
            value={users}
            filters={filters}
            paginator
            scrollable
            rows={5}
            rowsPerPageOptions={[5, 10, 15]}
            totalRecords={users.length}
          >
            <Column field='Id' header='Id' sortable />
            <Column field='Lastname' header='Last Name' sortable />
            <Column field='Firstname' header='First Name' sortable />
            <Column field='Email' header='Email' sortable />
            <Column field='Phone_no' header='Phone No' sortable />
            <Column field='Role' header='Role' sortable />
            <Column body={viewActions} header='Action' />
          </DataTable>
          {/* <UserList
            users={searchTerm.length < 1 ? currentPosts : searchResult}
            term={searchTerm}
            searchKeyword={searchHandler}
          /> */}
          {/* <Pagination
            totalPosts={users.length}
            postsPerPage={postsPerPage}
            setcurrentPage={setcurrentPage}
            currentPage={currentPage}
          /> */}
        </>
      )}
    </div>
  )
}

export default Users
