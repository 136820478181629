import { useState } from 'react'

const useDelete = () => {
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [resData, setResData] = useState(null)
  const token = JSON.parse(localStorage.getItem('token'))
  let jwt = ''
  if (token) {
    jwt = token.token
  }

  const deleteUser = async (formData, url) => {
    setIsLoading(true)
    setError(null)
    const response = await fetch(url, {
      method: 'Delete',
      headers: {
        'Authorization': 'Bearer ' + jwt,
      },
      body: formData,
    })
    try {
      const data = await response.text()
      if (!response.ok) {
          throw Error(data)
        }
        setResData(data)
        setIsLoading(false)
      
    } catch (error) {
      setError(error.message)
      setIsLoading(false)
    }
  }
  return { error, isLoading, resData, deleteUser }
}

export default useDelete
