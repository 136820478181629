import React, { useRef, useState } from 'react'
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup'
import { Toast } from 'primereact/toast'
import { Button } from 'primereact/button'
import useDelete from '../../hooks/useDelete'
import { removeUserUrl } from '../sub_pages/BaseUrl'
import { Dialog } from 'primereact/dialog';
        

function Privacy() {
  const toast = useRef(null)
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const {error, isLoading, resData, deleteUser} = useDelete()
  const [visible, setVisible] = useState(false)

  const accept = () => {
    if (email === "" || password === "") {
      
      toast.current.show({
        severity: 'info',
        summary: 'Warning',
        detail: 'Please fill your details',
        life: 3000,
      })
    }else{
      const formData = new FormData()
      formData.append('Email', email)
      formData.append('Password', password)
      deleteUser(formData, removeUserUrl)
    }

    if (resData) {
      // console.log(resData);
      toast.current.show({
        severity: 'warn',
        summary: 'Confirmed',
        detail: resData,
        life: 3000,
      })
    }
    if (error) {
      // console.log(error);
      toast.current.show({
        severity: 'info',
        summary: 'Rejected',
        detail: error,
        life: 3000,
      })
    }
  }

  const reject = () => {
    toast.current.show({
      severity: 'info',
      summary: 'Rejected',
      detail: 'You have rejected',
      life: 3000,
    })
  }
  const confirm = (event) => {
    event.preventDefault()
    confirmPopup({
      target: event.currentTarget,
      message: 'Do you want to delete this account?',
      icon: 'pi pi-info-circle',
      defaultFocus: 'reject',
      acceptClassName: 'p-button-danger',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      rejectClass: 'p-button-sm',
      acceptClass: 'p-button-outlined p-button-sm ',
      accept,
      reject,
    })
  }

  return (
    <div>
      <div className='mx-auto w-[900px] md:w-3/4 lg:w-[1000px] pb-3'>
        <h2 className='text-2xl font-bold p-2 m-1 underline text-center tracking-wider'>
          EASYREAD APP TERMS AND CONDITIONS
        </h2>
        <div className='p-3 '>
          <h4 className='p-2 text-lg font-bold'>Last Updated: [4/10/2023]</h4>
          <p className='leading-relaxed text-lg ml-4 text-justify'>
            Please carefully read these terms and conditions before using the
            Easyread mobile application ("the App"). By downloading and using
            the App, you agree to be bound by these terms and conditions.
          </p>
        </div>
        <div className='p-4'>
          <ol className='list-[decimal]'>
            <li className='text-lg'>
              <h4 className='text-lg font-bold'>Acceptance of Terms</h4>
              <p className='leading-relaxed text-justify'>
                By downloading and using the Easyread App, you agree to comply
                with and be bound by these terms and conditions. If you do not
                agree to these terms, please do not use the App.
              </p>
            </li>
            <li className='text-lg'>
              <h4 className='text-lg font-bold'>User Eligibility</h4>
              <p className='leading-relaxed text-justify'>
                You must be at least 16 years of age to use the App. By using
                the App, you represent and warrant that you are of legal age to
                form a binding contract with Easyread under Nigerian law and
                that you have full power and authority to use the App.
              </p>
            </li>
            <li className='text-lg'>
              <h4 className='text-lg font-bold'>Use of the App</h4>
              <ol className='list-[lower-alpha] ml-4 leading-relaxed'>
                <li className='leading-relaxed text-justify'>
                  You may use the App for personal, non-commercial purposes,
                  particularly for accessing and studying legal materials within
                  Nigeria.
                </li>
                <li className='leading-relaxed text-justify'>
                  You agree not to use the App in any way that violates Nigerian
                  laws and regulations.
                </li>
                <li className='leading-relaxed text-justify'>
                  You may not reverse engineer, decompile, or disassemble the
                  App or attempt to gain unauthorized access to the App's source
                  code.
                </li>
              </ol>
            </li>
            <li className='text-lg'>
              <h4 className='text-lg font-bold'>Privacy</h4>
              <p className='leading-relaxed text-justify'>
                Easyread respects your privacy. Please refer to our Privacy
                Policy [link to Privacy Policy] for details on how we collect,
                use, and disclose information following Nigerian data protection
                laws.
              </p>
            </li>
            <li className='text-lg'>
              <h4 className='text-lg font-bold'>Content </h4>
              <ol className='list-[lower-alpha] ml-4 leading-relaxed'>
                <li className='leading-relaxed text-justify'>
                  The App may contain user-generated content related to Nigerian
                  law and legal materials. You understand and agree that
                  Easyread is not responsible for any content posted by users
                  and does not endorse or verify the accuracy of user-generated
                  content.
                </li>
                <li className='leading-relaxed text-justify'>
                  You may not post or share content that is offensive, illegal,
                  or violates the rights of others under Nigerian law.
                </li>
              </ol>
            </li>
            <li className='text-lg'>
              <h4 className='text-lg font-bold'>Intellectual Property</h4>
              <ol className='list-[lower-alpha] ml-4 leading-relaxed'>
                <li className='leading-relaxed text-justify'>
                  The App, its content, and all related intellectual property
                  rights are owned by Easyread or its licensors and are
                  protected by Nigerian intellectual property laws.
                </li>
                <li className='leading-relaxed text-justify'>
                  You may not use, copy, or distribute the App or its content
                  without the express written permission of Easyread, in
                  compliance with Nigerian copyright and intellectual property
                  laws.
                </li>
              </ol>
            </li>
            <li className='text-lg'>
              <h4 className='text-lg font-bold'>Termination</h4>
              <p className='leading-relaxed text-justify'>
                Easyread may terminate or suspend your access to the App at its
                discretion, with or without notice, by Nigerian legal
                provisions.
              </p>
            </li>
            <li className='text-lg'>
              <h4 className='text-lg font-bold'>Disclaimers</h4>
              <p className='leading-relaxed text-justify'>
                The App is provided "as is" without any warranties, expressed or
                implied, under Nigerian law.
              </p>
            </li>
            <li className='text-lg'>
              <h4 className='text-lg font-bold'>Limitation of Liability</h4>
              <p className='leading-relaxed text-justify'>
                In no event shall Easyread or its affiliates be liable for any
                direct, indirect, incidental, special, or consequential damages
                arising out of or in any way connected with the use of the App,
                in compliance with Nigerian legal standards.
              </p>
            </li>
            <li className='text-lg'>
              <h4 className='text-lg font-bold'>Changes to Terms</h4>
              <p className='leading-relaxed text-justify'>
                Easyread may update these terms and conditions from time to
                time. It is your responsibility to review these terms
                periodically, as required by Nigerian legal regulations.
              </p>
            </li>
            <li className='text-lg'>
              <h4 className='text-lg font-bold'>Governing Law</h4>
              <p className='leading-relaxed text-justify'>
                These terms and conditions shall be governed by and construed by
                Nigerian laws and regulations, and any legal disputes shall be
                subject to the exclusive jurisdiction of Nigerian courts.
              </p>
            </li>
            <li className='text-lg'>
              <h4 className='text-lg font-bold'>Contact Information</h4>
              <p className='leading-relaxed text-justify'>
                If you have any questions about these terms and conditions,
                please contact us at [+2347035969586]. The Easyread App is
                designed for accessing and studying law books and materials
                within Nigeria, and these terms and conditions are tailored to
                Nigerian legal requirements.
              </p>
            </li>
          </ol>
        </div>
        <div className='p-3'>
          <h3 className='text-2xl font-bold p-2 m-1 underline text-center tracking-wider'>
            PRIVACY POLICY
          </h3>
        </div>
        <div className='p-3'>
          <h4 className='text-lg font-bold'>Last Updated: [4/11/2023]</h4>
          <p className='text-lg leading-relaxed ml-4 text-justify'>
            Easyread ("we," "our," or "us") is committed to protecting your
            privacy and ensuring the security of your personal information while
            using the Easyread mobile application ("the App"). This Privacy
            Policy outlines our practices regarding the collection, use, and
            sharing of your data. By using the App, you consent to the terms of
            this Privacy Policy.
          </p>
        </div>
        <div className='p-0'>
          <ol className='list-[decimal]'>
            <li className='text-lg'>
              <h4 className='text-lg font-bold'>Information We Collect:</h4>
              <ol className='list-[lower-alpha] ml-4 leading-relaxed'>
                <li className='leading-relaxed text-justify'>
                  User-Provided Information: When you use the App, we may
                  collect personal information you voluntarily provide, such as
                  your name, email address, and other contact details.
                </li>
                <li className='leading-relaxed text-justify'>
                  Usage Information: We may collect information about your use
                  of the App, such as the content you access, the time and
                  duration of your activities, and your device information.
                </li>
              </ol>
            </li>
            <li className='text-lg'>
              <h4 className='text-lg font-bold'>
                How We Use Your Information:
              </h4>
              <p>
                We may use the information we collect for the following
                purposes:
              </p>
              <ol className='list-[lower-alpha] ml-4 leading-relaxed'>
                <li className='leading-relaxed text-justify'>
                  To Provide and Personalize the App: We use your information to
                  operate, maintain, and improve the App, as well as to tailor
                  your experience within the App.
                </li>
                <li className='leading-relaxed text-justify'>
                  To Communicate: We may use your contact information to send
                  you notifications, updates, and other essential information.
                </li>
                <li className='leading-relaxed text-justify'>
                  To Comply with Legal Obligations: We may process your
                  information to comply with legal requirements and regulations
                  in Nigeria.
                </li>
              </ol>
            </li>
            <li className='text-lg'>
              <h4 className='text-lg font-bold'>
                Data Sharing and Disclosure:
              </h4>
              <ol className='list-[lower-alpha] ml-4 leading-relaxed'>
                <li className='leading-relaxed text-justify'>
                  Third-Party Service Providers: We may share your information
                  with third-party service providers who assist us in delivering
                  the App's services, subject to their compliance with Nigerian
                  data protection laws.
                </li>
                <li className='leading-relaxed text-justify'>
                  Legal Compliance: We may disclose your information to comply
                  with legal obligations or to protect our rights, privacy,
                  safety, or property, or that of our users or the public.
                </li>
              </ol>
            </li>
            <li className='text-lg'>
              <h4 className='text-lg font-bold'>Security:</h4>
              <p className='leading-relaxed text-justify'>
                We employ reasonable security measures to safeguard your
                information from unauthorized access, disclosure, alteration,
                and destruction. However, no online data transmission or storage
                can be entirely secure, and we cannot guarantee the security of
                your data.
              </p>
            </li>
            <li className='text-lg'>
              <h4 className='text-lg font-bold'>Your Choices and Rights:</h4>
              <ol className='list-[lower-alpha] ml-4 leading-relaxed'>
                <li className='leading-relaxed text-justify'>
                  Access and Correction: You have the right to access and
                  correct your personal information. You may do this by
                  contacting us through the information provided below.
                </li>
                <li className='leading-relaxed text-justify'>
                  Data Deletion: You may request the deletion of your data by
                  contacting us. We will process such requests in accordance
                  with Nigerian law.
                </li>
              </ol>
            </li>
            <li className='text-lg'>
              <h4 className='text-lg font-bold'>
                Changes to this Privacy Policy:
              </h4>
              <p className='leading-relaxed text-justify'>
                We may update this Privacy Policy from time to time. Any changes
                will be effective upon posting. You are encouraged to review
                this policy periodically.
              </p>
            </li>
            <li className='text-lg'>
              <h4 className='text-lg font-bold'>Contact Us: </h4>
              <p className='leading-relaxed text-justify'>
                If you have questions or concerns about this Privacy Policy or
                your data, please contact us at [+2347035969586].
              </p>
            </li>
            <li>
              <h4 className='text-lg font-bold'>Deletion of account:</h4>
              <h4 className='text-lg font-bold my-3'>Important to Note:</h4>
              <ul className='list-disc ml-4 my-3 leading-relaxed'>
                <li>
                  Deleting your account is permanent. Once it's gone, you won't
                  be able to recover your data.
                </li>
              </ul>
              <h4 className='text-lg font-bold my-3'>Still have questions?</h4>
              <p className='leading-relaxed text-justify mb-3'>
                Please contact us at [+2347035969586].
              </p>
              <p className='leading-relaxed text-justify'>
                For a user that want their account to be deleted should fill the
                form <Button label="here" icon="pi pi-external-link" onClick={() => setVisible(true)} /> .
              </p>
            </li>
          </ol>
              <Dialog header="Delete Account" visible={visible} style={{ width: '50vw' }} onHide={() => setVisible(false)}>
                <div className='form-group'>
                  <div className='my-3'>
                    <input
                      type='email'
                      className='p-3 rounded-md w-full'
                      placeholder='Email'
                      onChange={(e) => {
                        e.preventDefault()
                        setEmail(e.target.value)
                      }}
                    />
                  </div>
                  <div className='my-3'>
                    <input
                      type='password'
                      className='p-3 rounded-md w-full'
                      placeholder='Password'
                      onChange={(e) => {
                        e.preventDefault()
                        setPassword(e.target.value)
                      }}
                    />
                  </div>
                  <div className='form-group'>
                    <Toast ref={toast} />
                    <ConfirmPopup className='w-full' />
                    <div className=''>
                      <Button
                        onClick={confirm}
                        icon='pi pi-times'
                        label={isLoading? 'Loading...': 'Delete'}
                        disabled={isLoading}
                        className='p-button-danger w-full p-3 bg-red-600 text-white'
                      ></Button>
                    </div>
                  </div>
                </div>
              </Dialog>
        </div>
      </div>
    </div>
  )
}

export default Privacy
