import React from 'react'

const PaginationPayment = ({
  totalCount,
  itemPerPage,
  setcurrentPage,
  currentPage,
  setPageSize,
  pageSize,
}) => {
    const pageNumbers = []
    const totalPage = Math.ceil(totalCount / itemPerPage)
    for (let i = 1; i <= totalPage; i++) {
      pageNumbers.push(i)
    }
    const setNextPage = () => {
        if (currentPage < totalPage) {
            setcurrentPage(currentPage + 1)
        }
    }
    const setPrevPage = () => {
        if (currentPage > 1) {
            setcurrentPage(currentPage - 1)
        }
    }
    const myPageSize = (size) => {
        setcurrentPage(1)
        setPageSize(size)
    }
  return (
    <div className='bg-slate-100 rounded-md flex justify-end mb-3'>
      <div className='w-auto flex justify-between items-center px-3 py-3 gap-3'>
        <div>
          <button onClick={setPrevPage} className='text-2xl'>
            &lt;
          </button>
        </div>
        <div className='flex justify-center items-center gap-3'>
          {pageNumbers.map((number) => (
            <button
              key={number}
              onClick={() => setcurrentPage(number)}
              className={`${
                currentPage === number
                  ? 'bg-blue-600 text-white'
                  : 'bg-white text-blue-600'
              } px-3 py-1 rounded-md`}
            >
              {number}
            </button>
          ))}
        </div>
        <div>
          <button onClick={setNextPage} className='text-2xl'>
            &gt;
          </button>
        </div>
        <div className='p-3 bg-slate-300 w-20 rounded-md'>
          <select
            className='border border-gray-300 rounded-md px-2 py-1 w-full'
            onChange={(e) => myPageSize(e.target.value)}
          >
            {pageSize === 5 ? (
              <option value='5' selected>
                5
              </option>
            ) : (
              <option value='5'>5</option>
            )}

            {pageSize === 10 ? (<option value='10' selected>10</option>): (<option value='10'>10</option>)}
            {pageSize === 15 ? (<option value='15' selected>15</option>): (<option value='15'>15</option>)}
            {pageSize === 20 ? (<option value='20' selected>20</option>): (<option value='20'>20</option>)}
          </select>
        </div>
      </div>
    </div>
  )
}

export default PaginationPayment