import React, { useState } from 'react'
import useFetch from '../../hooks/useFetch'
// import PaymentList from '../sub_pages/PaymentList'
import Loading from '../sub_pages/Loading'
import Breadcrumbs from '../sub_pages/Breadcrumbs'
import { useLocation } from 'react-router-dom'
import { getAllPaymentUrl } from '../sub_pages/BaseUrl'
// import Pagination from '../sub_pages/Pagination'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import PaginationPayment from '../sub_pages/PaginationPayment'

const Payments = () => {
  // const [searchTerm, setSearchTerm] = useState('')
  // const [searchResult, setSearchResult] = useState([])
  // const [currentPage, setcurrentPage] = useState(1)
  // const [postsPerPage] = useState(10)
  const location = useLocation()
  // const token = localStorage.getItem('token')
  // const jwt = token.token
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  let allPayments = []
  let paymentCount = 0
  let nextPage = null
  let prevPage = null

  const {
    data: payments,
    isPending,
    error,
  } = useFetch(
    getAllPaymentUrl + '?pageNumber=' + pageNumber + '&pageSize=' + pageSize
  )

  const searchHandler = (searchTerm) => {
    // setSearchTerm(searchTerm)
    if (searchTerm !== '') {
      const newPaymentList = payments.filter((payment) => {
        return Object.values(payment)
          .join(' ')
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      })
      // setSearchResult(newPaymentList)
    } else {
      // setSearchResult(payments)
    }
  }
  if (payments) {
    allPayments = payments.allPaymentData
    paymentCount = payments.totalNumber
    nextPage = payments.nextPage
    prevPage = payments.previousPage
  }
  
  const getUserName = (rowData) => {
    return rowData.User.Firstname + ' ' + rowData.User.Lastname
  }
  const getBookTitle = (rowData) => {
    return rowData.Book.Title
  }
  const getAmount = (rowData) => {
    return rowData.Book.Price
  }

  return (
    <div className='container mx-auto md:px-8 min-h-screen'>
      {!isPending && (
        <Breadcrumbs
          location={location.pathname}
          userSearchKeyword={searchHandler}
        />
      )}
      {isPending && <Loading />}
      {error && <div>{error}</div>}
      {allPayments && (
        <div className='mt-6'>
          <input
            type='text'
            placeholder='Search'
            className='w-full p-3 rounded-md my-3'
            onInput={(e) => searchHandler(e.target.value)}
          />
          <DataTable
            value={allPayments}
            scrollable
            rows={pageSize}
            totalRecords={allPayments.length}
          >
            <Column field='Id' header='Id' sortable />
            <Column body={getUserName} header='Name' />
            <Column body={getAmount} header='Amount' sortable />
            <Column body={getBookTitle} header='Book Title' sortable />
            <Column field='Payment_Status' header='Status' sortable />
            <Column field='Payment_made_at' header='Payed At' sortable />
          </DataTable>
          <div className="pagination">
            <PaginationPayment
              totalCount={paymentCount}
              itemPerPage={pageSize}
              setcurrentPage={setPageNumber}
              currentPage={pageNumber}
              nextPage={nextPage}
              prevPage={prevPage}
              setPageSize={setPageSize}
              pageSize={pageSize}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default Payments
